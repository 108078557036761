@use '@angular/material' as mat;
@include mat.core();

$mat-lime: (
  50: #f9fbe7,
  100: #f0f4c3,
  200: #e6ee9c,
  300: #dce775,
  400: #d4e157,
  500: #cddc39,
  600: #c0ca33,
  700: #afb42b,
  800: #9e9d24,
  900: #827717,
  A100: #f4ff81,
  A200: #eeff41,
  A400: #c6ff00,
  A700: #aeea00,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: rgba(black, 0.87),
    500: rgba(black, 0.87),
    600: rgba(black, 0.87),
    700: rgba(black, 0.87),
    800: rgba(black, 0.87),
    900: rgba(white, 0.87),
    A100: rgba(black, 0.87),
    A200: rgba(black, 0.87),
    A400: rgba(black, 0.87),
    A700: rgba(black, 0.87),
  )
);

$mat-red: (
  50: #ffebee,
  100: #ffcdd2,
  200: #ef9a9a,
  300: #e57373,
  400: #ef5350,
  500: #f44336,
  600: #e53935,
  700: #d32f2f,
  800: #c62828,
  900: #b71c1c,
  A100: #ff8a80,
  A200: #ff5252,
  A400: #ff1744,
  A700: #d50000,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: rgba(black, 0.87),
    500: rgba(white, 0.87),
    600: rgba(white, 0.87),
    700: rgba(white, 0.87),
    800: rgba(white, 0.87),
    900: rgba(white, 0.87),
    A100: rgba(black, 0.87),
    A200: rgba(white, 0.87),
    A400: rgba(white, 0.87),
    A700: rgba(white, 0.87),
  )
);


$capRock-blue: (
  50: #c0defc,
  100: #91c2f2,
  200: #74abe2,
  300: #5994ce,
  400: #3c79b5,
  500: #265b97,
  600: #1c4b7a,
  700: #153b60,
  800: #0e2944,
  900: #071726,
  A100: #82b1ff,
  A200: #448aff,
  A400: #2979ff,
  A700: #2962ff,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: rgba(black, 0.87),
    500: white,
    600: white,
    700: white,
    800: rgba(white, 0.87),
    900: rgba(white, 0.87),
    A100: rgba(black, 0.87),
    A200: white,
    A400: white,
    A700: white,
  )
);

$capRock-light-blue: (
  50: #e1f5fe,
  100: #cde4f9,
  200: #b9d4ef,
  300: #a6c4e2,
  400: #91afce,
  500: #7c9cc0,
  600: #6988a8,
  700: #57738e,
  800: #465e75,
  900: #354759,
  A100: #80d8ff,
  A200: #40c4ff,
  A400: #00b0ff,
  A700: #0091ea,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: rgba(black, 0.87),
    500: white,
    600: white,
    700: white,
    800: white,
    900: rgba(white, 0.87),
    A100: rgba(black, 0.87),
    A200: rgba(black, 0.87),
    A400: rgba(black, 0.87),
    A700: white,
  )
);

$capRock-green: (
  50: #f5f7e9,
  100: #e5ecc8,
  200: #d3dfa6,
  300: #c2d283,
  400: #b4c968,
  500: #a8bf4e,
  600: #97b046,
  700: #829c3c,
  800: #6e8834,
  900: #4c6724,
  A100: #cbe4c5,
  A200: #abd3a1,
  A400: #8ac47c,
  A700: #72b760,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: rgba(black, 0.87),
    500: white,
    600: white,
    700: white,
    800: white,
    900: rgba(white, 0.87),
    A100: rgba(black, 0.87),
    A200: rgba(black, 0.87),
    A400: rgba(black, 0.87),
    A700: white,
  )
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue.
$rightbridge-primary: mat.define-palette($capRock-blue);
$rightbridge-accent:  mat.define-palette($capRock-green);

// The warn palette is optional (defaults to red).
$rightbridge-warn: mat.define-palette($mat-red);


// // Create a config with the default typography levels.
// $custom-headers: mat.define-typography-config(
//   h1 (
//     font-family: '"Dosis", sans-serif'
//   )
// );

// Custom header that uses only the Material `font-size` and `font-family`.
html {
  font-size: 18px;
}
h1, h2, h3, h4, h5, h6 {
  font-family: mat-font-family('"Dosis", sans-serif');
}

// Create the theme object (a Sass map containing all of the palettes).
$rightbridge-theme: mat.define-light-theme((
  color: (
    primary: $rightbridge-primary,
    accent: $rightbridge-accent,
    warn: $rightbridge-warn
  ),
  density: -1
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
// @include angular-material-theme($rightbridge-theme);
@include mat.all-component-themes($rightbridge-theme);
// @include mat.hea typography-hierarchy($rightbridge-theme);
