$cp-blue: #265b97;
$cp-light-blue: #7c9cc0;
$cp-green: #829c3c;
$cp-light-green: #a3bc3d;
$cp-yellow-green: #a3b738;
$cp-red: #bf2025;
$cp-light-red: #ef4b26;
$cp-gray: #4c4c4e;
$cp-med-gray: #939597;
$cp-light-gray: #e6e7e8;
$cp-yellow: #dc9c00;
$cp-army: #52590e;
$cp-orange: #d76821;
$cp-dark-purple: #331e7d;
$white: #fff;
$black: #4c4c4e;
$real-black: #000;
$grid-breakpoints: (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px) !default;
$font-family: "Dosis",
sans-serif;
$rb-font-family: "Roboto",
sans-serif;