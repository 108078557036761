@use "sass:math";
@import '_variables.scss';
@mixin aspect-ratio($width, $height) {
    position: relative;
    &:before {
        display: block;
        content: "";
        width: 100%;
        padding-top: math.div($height, $width) * 100%;
    }
    >.content {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}

@mixin media-up($key) {
    @media screen and (min-width: map-get($grid-breakpoints, $key)) {
        @content
    }
}

@mixin media-down($key) {
    @media screen and (max-width: map-get($grid-breakpoints, $key)) {
        @content
    }
}

@mixin gradient($start-color: #555, $end-color: #333, $deg: 90deg) {
    background-repeat: repeat-x;
    background-image: -webkit-linear-gradient($deg, $start-color, $end-color); // Safari 5.1-6, Chrome 10+
    background-image: -o-linear-gradient($deg, $start-color, $end-color); // Opera 12
    background-image: linear-gradient($deg, $start-color, $end-color); // Standard, IE10, Firefox 16+, Opera 12.10+, Safari 7+, Chrome 26+
}
