/* You can add global styles to this file, and also import other style files */
//
// Might need to run and additional migration script in the future
// https://stackoverflow.com/questions/75311923/problems-after-angular-material-update-from-14-to-15
// https://rc.material.angular.io/guide/mdc-migration
//
@import '@angular/material/prebuilt-themes/indigo-pink.css';

// @import "~@progress/kendo-theme-default/scss/grid";
@import '/node_modules/@progress/kendo-theme-material/scss/all';
@import './app/css/_variables.scss';
@import './app/css/_mixins.scss';

// @import './app/css/material-icons.scss';
// @import './app/css/roboto.scss';
// @import './app/css/dosis.scss';

html,
body {
  margin: 0;
  height: 100%;
  color: $black;
}

.mat-mdc-dialog-container {
  max-height: 85vh;

  .mat-mdc-dialog-surface {
    padding: 15px;
  }
}

label.section {
  font-weight: 700;
  padding: 0;
  margin-bottom: 10px;
}

.page-container {
  overflow: hidden;
  padding-top: 40px;
  padding-bottom: 40px;
  min-height: 80vh;

  &.unified {
    background: $cp-blue;
    background: radial-gradient(
      at 50% 35%,
      $cp-blue 45%,
      darken($cp-blue, 15) 100%
    );
    width: 100%;
    max-width: 100%;
    padding: 40px 40px;
    color: $white;
    min-height: calc(100vh - 112px);
  }
}

app-left-nav {
  position: fixed;
  height: calc(100vh - 102px);
  overflow-y: auto;
  top: 104px;

  &::-webkit-scrollbar {
    display: none;
  }
}

.custom-tabs {
  padding: 25px 35px 35px !important;
  .mat-mdc-tab-labels {
    align-items: end;
  }
  .mat-mdc-tab {
    background: transparentize($white, .7);
    margin: 0 2px;
    &:first-of-type {
      border-radius: 5px 0 0 0;
      margin-left: 0;
    }
    &:last-of-type {
      border-radius: 0 5px 0 0;
      margin-right: 0;
    }
    &.mdc-tab--active {
      background: transparentize($white, .3);
      border-radius: 5px 5px 0 0;
    }
  }
  .mat-mdc-tab {
    transition: 350ms;

    &.mdc-tab--active {
      height: 40px;
    }
  }
  .mdc-tab-indicator {
    left: initial;
    width: 85%;
  }
  .mat-mdc-tab-body.mat-mdc-tab-body-active {
    background: transparentize($white, .3);
    border-radius: 0 5px 5px 5px;
    padding: 20px 30px;
    &:first-of-type {
      border-radius: 0 5px 5px 5px;
    }
    &:last-of-type {
      border-radius: 5px 0 5px 5px;
    }
  }
  &.blue {
    .mat-mdc-tab {
      background: transparentize($cp-blue, .95);
      &.mdc-tab--active {
        background: transparentize($cp-blue, .85);
        border-radius: 5px 5px 0 0;
      }
    }
    .mat-mdc-tab-body.mat-mdc-tab-body-active {
      background: transparentize($cp-blue, .85);
    }
  }
}


md-select.mat-mdc-select.custom {
  margin-left: auto;
  padding: 0;
  min-width: 180px;
  /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
  .mat-select-value .mat-select-value-text,
  .mat-select-placeholder,
  .mat-select-arrow {
    color: $white !important;
    font-size: 90%;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
  .mat-select-underline {
    background: $white !important;
  }
}

.app-sidenav button {
  text-align: left;
}

h1 {
  z-index: 10;
  position: relative;

  &.header {
    padding: 0 0 5px 0;
    margin-bottom: 40px;
    border-bottom: 2px solid $cp-blue;
    color: $cp-blue;
    font-weight: 600;

    @include media-down(lg) {
      text-align: center;
    }
  }

  &.unified-header {
    padding: 0 0 5px 0;
    margin-bottom: 40px;
    color: $white;
    font-weight: 600;
  }
}

.highcharts-background {
  background: transparent;
}

.chart {
  margin-top: -60px;

  .highcharts-container {
    margin: 0 auto;

    @include media-up(lg) {
      margin: 0;
    }
  }
}

// .ngx-datatable .datatable-row-left,
// .ngx-datatable .datatable-row-center,
// .ngx-datatable .datatable-row-grouping,
// .ngx-datatable .datatable-row-right {
//     display: flex;
// }
.ngx-datatable.material {
  box-shadow: none !important;
  background: none !important;

  .datatable-header {
    border-bottom: none !important;

    .datatable-header-inner {
      height: 100%;
    }

    .datatable-header-cell {
      background-color: $cp-light-green !important;
      color: $white !important;
      font-size: 105% !important;
      font-weight: 700 !important;

      &:nth-child(even) {
        background-color: $cp-green !important;
      }
    }
  }

  .datatable-body-row {
    .datatable-body-cell {
      border-bottom: 1px solid $cp-med-gray;

      &:nth-child(even) {
        background: $cp-light-gray;
      }
    }
  }
}

.search-table-container
  .ngx-datatable.material:not(.cell-selection)
  .datatable-body-row:hover,
.search-table-container
  .ngx-datatable.material:not(.cell-selection)
  .datatable-body-row:hover
  .datatable-row-group {
  background-color: fade-out($cp-light-blue, 0.8) !important;
  cursor: pointer;

  .datatable-body-cell {
    &:nth-child(even) {
      background: fade-out($cp-light-blue, 0.8);
    }
  }
}



.app-multi-dialog {
  max-width: none;
  overflow: hidden;
  width: 750vw;
  height: 90vh;

  .dialog-container,
  .mat-mdc-dialog-container {
    overflow: hidden;
    overflow-y: auto;
  }

  .mat-content {
    overflow: visible;
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
.mat-mdc-button-toggle-label {
  margin-bottom: 0;
}

/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
.select-button.mat-mdc-button-toggle:not(.mat-mdc-button-toggle-disabled) {
  background-color: $white;
  color: $black;
}

.mat-mdc-button-disabled {
  color: $cp-light-gray
}

/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
.select-button.mat-button-toggle.mat-button-toggle-checked {
  background-color: $cp-light-green;
  color: $white;
}

/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
.select-button.mat-mdc-button-toggle.mat-mdc-button-toggle-checked.mat-mdc-button-toggle-disabled {
  background-color: fade-out($cp-light-green, 0.7);
}

.mat-mdc-checkbox-checked.mat-accent .mat-mdc-checkbox-ripple .mat-mdc-ripple-element {
  background-color: $cp-green !important;
  border-color: $cp-green !important;
}

.mat-mdc-checkbox.mat-accent {
  label.mdc-label {
    margin-bottom: 0;
  }
}

.mat-mdc-checkbox-checked.mat-accent .mdc-checkbox__background,.mdc-checkbox-indeterminate.mat-accent .mdc-checkbox__background {
  background-color: $cp-green!important;
  border-color: $cp-green!important;

  svg.mdc-checkbox__checkmark {
    color: $white !important;
  }
}

.mdc-switch__icons {
  display: none;
}

.mat-mdc-radio-checked.mat-accent .mdc-radio__outer-circle {
  border-color: $cp-green !important;
}
.mat-mdc-radio-checked.mat-accent .mdc-radio__inner-circle {
  border-color: $cp-green !important;
}

.mdc-label {
  margin-bottom: 0;
}

.details .reasonEmphasize {
  color: $cp-blue;
  font-style: italic;
}

.hidden {
  height: 0;
  visibility: hidden;
}

.w-95 {
  width: 94% !important;
  max-width: 95% !important;

  &.flex {
    flex: 0 0 95%;
  }
}

.w-90 {
  width: 90% !important;
  max-width: 90% !important;

  &.flex {
    flex: 0 0 90%;
  }
}

.w-85 {
  width: 85%;
  max-width: 85%;

  &.flex {
    flex: 0 0 85%;
  }
}

.w-80 {
  width: 80%;
  max-width: 80%;

  &.flex {
    flex: 0 0 80%;
  }
}

.w-70 {
  width: 70%;
  max-width: 70%;

  &.flex {
    flex: 0 0 70%;
  }
}

.w-66 {
  width: 66%;
  max-width: 66%;

  &.flex {
    flex: 0 0 66%;
  }
}

.w-33 {
  width: 33%;
  max-width: 33%;

  &.flex {
    flex: 0 0 33%;
  }
}

.w-15 {
  width: 15%;
  max-width: 15%;

  &.flex {
    flex: 0 0 15%;
  }
}

.w-10 {
  width: 10%;
  max-width: 10%;

  &.flex {
    flex: 0 0 10%;
  }
}

.w-5 {
  width: 5%;
  max-width: 5%;

  &.flex {
    flex: 0 0 5%;
  }
}

.pull-left {
  float: left !important;
}

.pull-right {
  float: right !important;
}

mat-icon {
  display: flex;
  overflow: visible !important;
}

.cdk-overlay-pane.mat-mdc-select-panel-above {
  margin-bottom: -45px !important;
  .mat-mdc-select-panel {
    margin-left: 10px !important;
    min-width: 102% !important;
  }
}
.cdk-overlay-pane {
  .mat-mdc-select-panel {
    margin-top: -45px !important;
    margin-left: 10px !important;
    min-width: 102% !important;
  }
}

app-utility-toolbar:not(.vertical) {
  position: fixed;
  top: 60px;
  background: rgba(#fafafa, .80);
  z-index: 99;
  width: 100%;
}

.toolbar-parent {
  position: relative;
  margin-top: 50px;
}

.training {
  display: flex;
  .content {
    width: 75vw;
  }
  .description {
    font-size: 97%;
    width: 100%;
  }
}
